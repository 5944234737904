<template>
  <div id="app" class="bg-teal-darkest min-h-full w-full" v-if="languageLoaded">
    <app-down v-if="appDown" />
    <portal-target name="menus" />
    <portal-target name="modals" />
    <loader v-if="this.appLoading"></loader>
    <div v-if="!isOnline" class="offline-notice">{{ $t('labels.offline') }}</div>
    <navigation v-if="this.$store.state.core.online && !this.$store.state.core.singleView"></navigation>
    <v-tour name="tour" :steps="inviteTour.steps" :options="inviteTour.options"></v-tour>
    <tours></tours>
    <div v-if="device === 'android'" class="device-menu-location hidden position-fixed right-2 top"></div>
    <div v-else class="device-menu-location hidden position-fixed center bottom"></div>
    <div id="queue" class="fixed left-6 right-6 z-20 animate-queue-in" v-if="axiosQueue && axiosQueue.length > 0">
      <queue v-for="(next, key) in axiosQueue" :key="'queue' + key" :queue="next" />
    </div>
    <router-view class="w-full"
                 :error.sync="error"
                 :authenticate="authenticate"
                 v-if="this.$store.state.core.online || this.$route.path == '/auth/' || this.$route.path == '/denied'">
    </router-view>
    <TempApp />
  </div>
</template>

<script>
import navigation from './components/Navigation'
import TempApp from './TempApp.vue'
import loader from './components/general/loading-spinner.vue'
import axios from 'axios'
import { mapState } from 'vuex'
import { get } from '@/composables/serverInterface.js'
import { requestLanguage } from './i18n'

export default {
  name: 'App',
  components: {
    navigation,
    loader,
    TempApp
  },
  data () {
    return {
      filters: this.$store.state.core.filters,
      languageLoaded: false,
      authClass: 'in',
      welcomeClass: null,
      appLoading: false,
      error: false,
      dataRefresh: false,
      pollServer: null,
      userLanguage: 'en',
      inviteTour: {
        options: {
          labels: {
            buttonStop: 'I Understand'
          }
        },
        steps: [
          {
            target: '.device-menu-location',
            content: '<strong>Action Required!</strong><br><br>You\'ve arrived here from a temporary link sent via SMS/Email, that link will expire soon.<br><br>It\'s recommended you save the Service App to your device, do so by accessing the browser menu and selecting \'Add to Home screen\', or alternatively save a bookmark.',
            params: {
              placement: 'bottom'
            }
          }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      cache: state => state,
      isOnline: state => state.core.isOnline,
      autoRefresh: state => state.core.autoRefresh,
      tour: state => state.core.tour,
      localCache: state => state.core.localCache,
      remoteCache: state => state.core.remoteCache,
      axiosQueue: state => state.tuxedo.axiosQueue,
      appDown: state => state.core.appDown
    }),
    device () {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      if (/android/i.test(userAgent)) return 'android'
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return 'ios'
      return 'unknown'
    }
  },
  watch: {
    autoRefresh: function (val) {
      if (val && !this.dataRefresh) {
        console.log('Auto Refresh Started!')
        this.dataRefresh = setInterval(() => {
          // TODO: Only need to refresh addresses rather than all assignments for speed and data
          this.$store.dispatch('GET_ASSIGNMENTS')
          this.$store.dispatch('GET_ADDRESS_OPTIONS')
        }, 60 * 1000)
      } else {
        console.log('Auto Refresh Cleared')
        clearInterval(this.dataRefresh)
      }
    }
  },
  methods: {
    authenticate () {
      if (this.isOnline === null) {
        // Offline, we'll let them through for now
        if (this.$store.state.core.temp_url) {
          const url = this.$store.state.core.temp_url
          this.$store.state.core.temp_url = null
          this.$router.push('/' + url)
        } else {
          this.$router.push('/?t=' + this.$store.state.core.token)
        }
      } else if (this.$store.state.core.token || this.$route.query.t) {
        const url = '/api/auth?api_token=' + this.$store.state.core.token
        axios.get(url)
          .then(response => {
            this.$store.commit('RESET_LOAD_REQUESTS')
            if (response.data.data.authorised === true) {
              this.$root.$i18n.locale = response.data.data.lang
              if (response.data.data.lang !== 'en') {
                requestLanguage(response.data.data.lang)
              }
              this.authClass = 'out'
              this.$store.commit('AUTHORISED', response.data.data)
              this.$store.commit('ASSIGN_PERMITS', response.data.data.permissions)
              this.$store.commit('UPDATE_REMOTE_CACHE', response.data.data.cache)
              setTimeout(() => {
                this.$store.state.core.lastAuth = new Date()
                this.appLoading = true
                this.welcomeClass = 'in'
                if (this.$store.state.core.temp_url) {
                  const url = this.$store.state.core.temp_url
                  this.$store.state.core.temp_url = null
                  this.$router.push('/' + url)
                } else if (this.$store.state.core.tempTo) {
                  const to = this.$store.state.core.tempTo
                  if (to.query.src) delete (to.query.src)
                  this.$router.push({ name: to.name, query: to.query })
                  this.$store.state.core.tempTo = null
                } else {
                  this.$router.push('/?t=' + this.$store.state.core.token)
                }
              }, 750)
              setTimeout(() => {
                this.$store.dispatch('GET_CONG_SETTINGS').then((res) => {
                  if (res.data.data.active) {
                    this.$store.state.core.singleView = false
                  }
                  this.$store.dispatch('LOAD_APP_DATA', { token: this.$store.state.core.token }).then(() => {
                    this.$store.dispatch('GET_LANGUAGES')
                    this.$store.dispatch('GET_ADDRESS_OPTIONS')
                    this.$store.dispatch('GET_ASSIGNMENTS')
                    get(['parts'])
                    get(['roles'])
                    get(['groups'])
                    console.log('finished')
                    this.welcomeClass = 'out'
                    // eslint-disable-next-line no-return-assign
                    setTimeout(() => this.appLoading = false, 750)
                  }, (error) => {
                    console.log(error)
                    this.appLoading = false
                  })
                }, (error) => {
                  console.log(error)
                  this.appLoading = false
                })
              }, 0)
            } else {
              this.$store.state.core.deniedReason = response.data.data.reason
              this.$store.commit('UNAUTHORISED')
              this.$router.push('/denied')
            }
          })
          .catch(e => {
            this.error = e
          })
      } else {
        this.$store.commit('UNAUTHORISED')
        this.$router.push('/denied')
      }
    }
  },
  mounted: function () {
    clearInterval(this.pollServer)
    this.pollServer = setInterval(() => {
      if (document.hasFocus()) {
        console.log('Polling server')

        this.$store.dispatch('POLL_SERVER')
      }
    }, 120 * 1000)

    if (this.$store.state.core.source === 'invite') {
      this.$tours.tour.start()
    }
  },
  beforeCreate () {
    requestLanguage('en').then(() => {
      this.languageLoaded = true
    })
  },
  created () {
    if (this.$route.hash) {
      const url = this.$route.hash.replace(/#/, '')
      const parts = url.split('?t=')
      this.$store.state.core.temp_url = parts[0]
      if (parts[1]) {
        this.$store.state.core.token = parts[1]
        this.$router.push('/auth/')
      } else {
        const url = this.$store.state.core.temp_url
        this.$store.state.core.temp_url = null
        this.$router.push('/' + url)
      }
    }
    if (this.$route.query.r) {
      this.$store.state.core.temp_url = this.$route.query.r
      console.log(this.$store.state.core.temp_url)
    }
    if (this.$route.query.s) {
      this.$store.state.core.singleView = this.$route.query.r
    }
    if (this.$route.query.t) {
      this.$store.state.core.token = this.$route.query.t
      this.$router.push('/auth/')
    }
    if (!this.$store.state.online && this.$route.path !== '/auth/') {
      if (this.$route.query.t) {
        this.$store.state.core.token = this.$route.query.t
      }
      this.$router.push('/auth/')
    }
  }
}
</script>

<style>
#app {
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-bottom: 72px;
}
</style>

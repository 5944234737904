import { defineStore } from 'pinia'
import { useCollectionsStore } from './collections'
import { remove } from 'lodash'
// import axios from 'axios'
// import { store } from '@/store'

export const useProfileStore = defineStore({
  id: 'profile',
  persist: true,
  state: () => ({
    user: {},
    darkMode: false,
    authenticated: false,
    lastAuthenticated: null,
    permits: {},
    appOnline: true,
    offlinePayloads: []
  }),
  getters: {},
  actions: {
    checkAuthentication () {
      // console.log(store.state.core.token)
      // return new Promise((resolve, reject) => {
      //   console.log('check authentication')
      //   axios.get('api/user')
      //     .then(res => {
      //       // const store = useCollectionsStore()
      //       // this.user = res.data.data
      //       // this.permits = res.data.data.permits
      //       // store.cache = res.data.data.cache
      //       // this.lastAuthenticated = Math.floor(new Date().getTime() / 1000)
      //       // resolve(res)
      //     })
      //     .catch(() => {
      //       // this.user = {}
      //       // this.authenticated = false
      //       // reject(err)
      //     })
      // })
    },
    sync () {
      const store = useCollectionsStore()
      // this.checkAuthentication()

      const promises = []
      promises.push(store.get('countries'))
      promises.push(store.get('regions'))
      promises.push(store.get('sharedCarts'))
      // promises.push(store.get('meetings'))
      // promises.push(store.get('permissions'))

      for (const payload of this.offlinePayloads) {
        promises.push(
          store.post(payload.uri, payload.payload)
            .then(() => {
              console.log(payload.id)
              remove(this.offlinePayloads, (p) => p.id === payload.id)
            })
            .catch(err => {
              console.log(err)
            })
        )
      }

      return Promise.all(promises)
    }
  }
})
